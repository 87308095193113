import {useCustomEvents, useGlobalParam, useNotifications, useRedirect} from "../../../hooks";
import {useEffect} from "react";
import {parse} from "query-string";
import {useDispatch} from "react-redux";
import {DIALOG_NOTIFICATION, ERROR_NOTIFICATION, notificationHide, TOAST_NOTIFICATION} from "../../../actions";

export const AuthEventKey = "AUTH_EVENT";

export enum AuthEventType {
    CHECK = "CHECK",
    ERROR = "ERROR",
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    CHANGE_ACCOUNT = "CHANGE_ACCOUNT"
}

export type AuthEventCheck = {
    type: AuthEventType.CHECK,
    params?: any // TODO: make this more strict
}
export type AuthEventError = {
    type: AuthEventType.ERROR,
    params?: any // TODO: make this more strict
}
export type AuthEventLogin = {
    type: AuthEventType.LOGIN,
    params?: any // TODO: make this more strict
}
export type AuthEventLogout = {
    type: AuthEventType.LOGOUT,
    params?: any // TODO: make this more strict
}
export type AuthEventChangeAccount = {
    type: AuthEventType.CHANGE_ACCOUNT,
    params: {
        accountId: string | number,
        updateInPlace: boolean
    }
}
export type AuthEvent = AuthEventCheck | AuthEventError | AuthEventLogin | AuthEventLogout | AuthEventChangeAccount;

export type AuthClientResolveResponse = {
    redirect?: string,
    loginUrl?: string,
} & Record<string, any>;
export type AuthClient = (type: AuthEventType, params?: AuthEvent["params"], globalParams?: any) => Promise<AuthClientResolveResponse>;

export type AuthProviderProps = {
    authClient: AuthClient | null
};

const getOrigin = (): string => {
    const reduxRouterPath = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if (reduxRouterPath.startsWith("/login")) {
        const existingOrigin = parse(reduxRouterPath.substring(reduxRouterPath.indexOf("?") + 1)) as (undefined | {
            origin: string
        });
        return existingOrigin?.origin || "/";
    }

    return reduxRouterPath;
};

export const AuthProvider = ({authClient}: AuthProviderProps): null => {
    const [globalParams, setGlobalParams] = useGlobalParam();
    const [lastEvent, postEvent] = useCustomEvents<AuthEvent>(AuthEventKey);
    const [show] = useNotifications(TOAST_NOTIFICATION, "cuda.auth.signInError", ERROR_NOTIFICATION);
    const dispatch = useDispatch();
    const performRedirect = useRedirect();

    useEffect(() => {
        if (!lastEvent?.type || !authClient) {
            return;
        }

        const eventOrigin = getOrigin();
        authClient(lastEvent.type, lastEvent.params, globalParams).then((response) => {
            if (!response) return;

            const {
                redirect,
                loginUrl = undefined,
                ...newParams
            } = response;
            const resolvedRedirect = redirect || (lastEvent.type === AuthEventType.LOGIN ? "/" : lastEvent.type === AuthEventType.LOGOUT ? "/login" : undefined);

            if ([AuthEventType.LOGIN, AuthEventType.CHANGE_ACCOUNT].includes(lastEvent.type)) {
                dispatch(notificationHide(DIALOG_NOTIFICATION));
                dispatch(notificationHide(TOAST_NOTIFICATION));
            }

            let originSettings = {};
            if (lastEvent.type === AuthEventType.LOGIN) {
                originSettings = {
                    origin: null,
                    previousOrigin: null
                };
            } else if (resolvedRedirect && resolvedRedirect !== eventOrigin && eventOrigin !== globalParams?.origin) {
                originSettings = {
                    origin: eventOrigin,
                    previousOrigin: lastEvent.type === AuthEventType.CHANGE_ACCOUNT ? "/" : globalParams?.origin || null
                };
            }

            setGlobalParams({
                ...newParams,
                loginUrl,
                ...originSettings
            });

            if (resolvedRedirect) {
                performRedirect(resolvedRedirect);
            }
        }).catch((error: any) => {
            const {
                message = undefined,
                redirect = undefined,
                loginUrl = undefined,
                ...newParams
            } = typeof error === "object" ? error : {message: typeof error === "string" ? error : "cuda.auth.signInError"};
            const resolvedRedirect = redirect || (lastEvent.type === AuthEventType.CHECK ? "/login" : undefined);

            let originSettings = {};
            if (resolvedRedirect && resolvedRedirect !== eventOrigin && eventOrigin !== globalParams?.origin) {
                originSettings = {
                    origin: eventOrigin,
                    previousOrigin: globalParams?.origin || null
                };
            }

            setGlobalParams({
                ...newParams,
                loginUrl,
                ...originSettings
            });

            if (message && [AuthEventType.LOGIN, AuthEventType.CHANGE_ACCOUNT].includes(lastEvent.type)) {
                show(TOAST_NOTIFICATION, message);
            }
            if (resolvedRedirect) {
                performRedirect(resolvedRedirect);
            } else if (lastEvent.type === AuthEventType.ERROR) {
                postEvent({type: AuthEventType.LOGOUT});
            }
        });
    }, [lastEvent]);

    return null;
};