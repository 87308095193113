import {useHistory} from "react-router-dom";
import {LocationDescriptor, Path} from "history";

let externalRedirectActive = false;

/**
 * Returns  a "push" function for redirecting to a new url.
 * If the provided URL is a local URL (i.e. starts with a "/") it uses the local react-router-dom push method to perform
 * the redirect. Otherwise it use window.location.
 */
export const useRedirect = (): ((url: Path | LocationDescriptor) => void) => {
    const {push} = useHistory() || {};

    const handlePush = (url: Path | LocationDescriptor) => {
        if (typeof url !== "string" || url.startsWith("/")) {
            if (push) {
                push(url);
            } else if (typeof url === "string") {
                window.location.href = url;
            }
        } else if (!externalRedirectActive) {
            externalRedirectActive = true;
            window.location.href = url;
        }
    };

    return handlePush;
};