import {merge} from "lodash";
import {AnyAction, Dispatch} from "redux";

/** @constant {string} action type for setting global params */
export const SET_GLOBAL_PARAMS = "CUDA/SET_GLOBAL_PARAMS";
/** @constant {string} action type for updating global param */
export const UPDATE_GLOBAL_PARAMS = "CUDA/UPDATE_GLOBAL_PARAM";

/**
 * Creates the SET_GLOBAL_PARAMS action, which shallow merges (i.e. Object.assign) the current Global Params with the params provided.
 *
 * @function
 * @param params object of parameters to merge into the current Global Params.
 * @returns action, ready to be used in a redux dispatch.
 */
export const setGlobalParams = (params: object) => ({
    type: SET_GLOBAL_PARAMS,
    params
});

/**
 * Creates the SET_GLOBAL_PARAMS action, which deep merges (i.e. lodash.merge) the current Global Params with the params provided.
 *
 * @function
 * @param params object of parameters to merge into the current Global Params.
 * @returns action, ready to be used in a redux dispatch.
 */
export const updateGlobalParams = (params: object) => ({
    type: UPDATE_GLOBAL_PARAMS,
    params
});

/**
 * thunk action creator for creating actions that have been enhanced with the current Global Parameters from the redux store.
 *
 * When the thunk action is resolved, it will dispatch and add the property "globalParams" to the provided action, which contains the Global Params
 * as they existed in the store at the time thunk action was resolved.
 *
 * @param {object} action the redux action to add "globalParams" to.
 * @returns {function} thunk action, ready to be used in a redux dispatch.
 */
export const enhanceActionWithGlobalParams = (action: AnyAction) => (dispatch: Dispatch, getState: Function) => {
    const state = getState();
    const globalParams = state && state.GlobalParamsReducer || {};
    dispatch({...action, globalParams: merge({}, globalParams)});
};