import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import {Button, Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    BANNER_NOTIFICATION,
    BannerNotification,
    DIALOG_NOTIFICATION,
    ERROR_NOTIFICATION,
    INFO_NOTIFICATION,
    Notification,
    NOTIFICATION_TYPE,
    notificationHide,
    OK_NOTIFICATION,
    TOAST_NOTIFICATION,
    ToastNotification,
    WARNING_NOTIFICATION
} from "../../../actions";
import StatusBanner from "../../layout/StatusBanner/StatusBanner";
import Toolbar from "../../layout/Toolbar/Toolbar";
import DialogBody from "../DialogBody/DialogBody";
import {usePrevious} from "../../../hooks";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {NotificationReducerState} from "../../../reducers/NotificationReducer/NotificationReducer";

const styles = (theme: Theme) => createStyles({
    root: {
        colorInherit: theme.palette.common.white
    },
    title: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    closeIcon: {
        color: theme.palette.common.white,
        float: "right"
    },
    dialogPaper: {
        minWidth: "fit-content"
    },
    dialogContent: {
        textAlign: "center",
        padding: 16
    },
    [INFO_NOTIFICATION]: {
        backgroundColor: theme.palette.primary.main
    },
    [ERROR_NOTIFICATION]: {
        backgroundColor: theme.palette.error.main
    },
    [WARNING_NOTIFICATION]: {
        backgroundColor: theme.palette.warning.main
    },
    [OK_NOTIFICATION]: {
        backgroundColor: theme.palette.success.main
    }
});
const useStyles = makeOverrideableStyles("NotificationDialog", styles);

interface ExtendedToastNotification extends ToastNotification {
    closed?: boolean,
    translateParams?: any
}

export interface NotificationDialogProps extends StyledComponentProps<typeof styles> {
}

/**
 * Displays notifications from the [notification](/?path=/docs/cudareactapp-notifications--notifications) redux flow. Renders Status banner / toast / dialog, or nothing, depending on active notifications.
 *
 * This component is rendered as a part of the [AppLayout](/?path=/docs/core-components-layout-applayout--app-layout) component, which it itself is rendered as a core part of the [CudaReactApp](/?path=/docs/core-components-cudareactapp--cuda-react-app).
 * There is no need to add this component yourself, unless you are making use of the notification Reducer without the full [CudaReactApp](/?path=/docs/core-components-cudareactapp--cuda-react-app).
 */
export const NotificationDialog = (props: NotificationDialogProps) => {
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const dispatch = useDispatch();
    const handleRequestClose = (type: NOTIFICATION_TYPE, content?: string) => () => dispatch(notificationHide(type, content));
    const notifications = useSelector((state: {
        NotificationReducer?: NotificationReducerState
    }) => state.NotificationReducer);
    const toastContents = (notifications?.[TOAST_NOTIFICATION] || []).map((notification: Notification) => notification.content);
    const closedToastNotifications = (usePrevious(notifications?.[TOAST_NOTIFICATION]) as ToastNotification[] || [])
        .filter((notification: ToastNotification) => !toastContents.includes(notification.content))
        .map((notification: ToastNotification): ExtendedToastNotification => ({...notification, closed: true}));
    const dialogContentArray: string[] = Array.isArray(notifications?.[DIALOG_NOTIFICATION]?.content) ? notifications?.[DIALOG_NOTIFICATION].content : [notifications?.[DIALOG_NOTIFICATION]?.content];

    return (
        <React.Fragment>
            {notifications?.[BANNER_NOTIFICATION] && notifications?.[BANNER_NOTIFICATION].map((bannerNotification: BannerNotification) => (
                <StatusBanner
                    key={bannerNotification.content}
                    open={true}
                    onClose={handleRequestClose(BANNER_NOTIFICATION, bannerNotification.content)}
                    dismissible={bannerNotification.dismissible}
                    classes={bannerNotification.level && {banner: classes[bannerNotification.level]}}
                    spinner={bannerNotification.spinner}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                bannerNotification.content || "",
                                bannerNotification.translateParams
                            )
                        }}
                    />
                </StatusBanner>
            ))}
            {notifications?.[DIALOG_NOTIFICATION] && (
                <Dialog
                    open
                    onClose={handleRequestClose(DIALOG_NOTIFICATION)}
                    className={classes.root}
                    classes={{paper: classes.dialogPaper}}
                >
                    <DialogBody
                        title="cuda.notification.title"
                        onClose={handleRequestClose(DIALOG_NOTIFICATION)}
                    >
                        <DialogContent className={classes.dialogContent}>
                            {dialogContentArray.map((content, index) => (
                                <Typography component="p" key={index}>
                                    {translate(content, notifications?.[DIALOG_NOTIFICATION].translateParams)}
                                </Typography>
                            ))}
                        </DialogContent>
                        <Toolbar>
                            <Button
                                onClick={handleRequestClose(DIALOG_NOTIFICATION)}
                                color="primary"
                                variant="contained"
                                size="small"
                            >
                                {translate("cuda.notification.ok")}
                            </Button>
                        </Toolbar>
                    </DialogBody>
                </Dialog>
            )}
            {[...(notifications?.[TOAST_NOTIFICATION] || []), ...closedToastNotifications].map((notification: ExtendedToastNotification, index: number) => (
                <Snackbar
                    key={notification && (notification.content)}
                    open={index === 0 && !notification.closed}
                    onClose={notification && handleRequestClose(TOAST_NOTIFICATION, notification.content)}
                    autoHideDuration={notification && notification.duration}
                    className={notification.level && classes[notification.level]}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                >
                    <SnackbarContent
                        className={notification.level && classes[notification.level]}
                        message={notification.content && translate(notification.content, notification.translateParams)}
                    />
                </Snackbar>
            ))}
        </React.Fragment>
    );
};

export default NotificationDialog;